.page-container {
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  scroll-snap-align: start;
  background-color: black;
}

.bio {
  color: white;
  margin: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: center;
}
.bio img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-right-style: solid;
  margin-left: 40px;
}
h1 {
  font-size: 50px;
}
h3 {
  font-size: 30px;
  margin-bottom: 30px;
}
p {
  margin: 30px;
  font-size: 25px;
}

@media (max-width: 768px) {
  .page-container {
    display: flex;
    align-items: center;
  }
  h1 {
    font-size: 20px;
    margin-top: 100px;
  }
  h3 {
    font-size: 18px;
  }
  p {
    font-size: 15px;
    margin: 10px;
  }

  .bio {
    display: block;
    justify-content: center;
  }
  .bio img {
    border-right-style: none;
    display: inline-block;
    margin: 0;
    border-radius: 20%;
  }
}
