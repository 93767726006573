* {
  margin: 0;
}

.app-container {
  scroll-snap-type: y mandatory;
  overflow: auto;
  height: 100vh;
}

@media (max-width: 768px) {
  .app-container {
    scroll-snap-type: none;
    height: 100%;
  }
}
