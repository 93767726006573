.project {
  height: 100vh;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  scroll-snap-align: start;
  background-color: white(100, 95, 95);
}

.project-container {
  display: flex;
  padding: 10px;
  margin-top: 40px;
  margin-left: 20px;
}

@media (max-width: 768px) {
  .project {
    height: 100%;
    width: 100%;
  }
  .project-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 0;
  }
}
