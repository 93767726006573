.contact-container {
  height: 100vh;
  width: 100vw;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  scroll-snap-align: start;
  background-color: black;
  align-items: center;
}

.contact-form {
  margin-top: 40px;
  color: white;
}
.contact-form > div {
  display: flex;
  flex-direction: column;
  margin: 2rem;
  font-size: 25px;
  align-items: center;
}

.contact-form label {
  margin: 1rem 0;
}

#input-area {
  border-radius: 3px;
  font-size: 20px;
}
#submit {
  margin: 1rem;
  font-size: 30px;
  font-weight: bold;
}

.bouncy {
  animation: bouncy 4s infinite linear;
  position: relative;
}

@keyframes bouncy {
  0% {
    top: 0em;
  }
  40% {
    top: 0em;
  }
  43% {
    top: -0.9em;
  }
  46% {
    top: 0em;
  }
  48% {
    top: -0.4em;
  }
  50% {
    top: 0em;
  }
  100% {
    top: 0em;
  }
}
.modal {
  position: relative;
  height: 100vh;
  width: 100vw;
  background-color: black;
  outline: none;
}
.modal-box {
  position: relative;
  height: 50%;
  width: 50%;
  top: 180px;
  margin-top: 20px;
  left: 480px;
  padding-top: 100px;
  justify-content: center;
  text-align: center;
  align-items: center;
  color: black;
  background-color: white;
  font-family: "iCielMedium", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  border-radius: 15px;
}

.modal-text-title {
  font-size: 50px;
  margin-bottom: 20px;
  margin-top: 90px;
}
.modal-text {
  margin-bottom: 20px;
}
button {
  font-size: 20px;
  font-weight: bold;
}
@media (max-width: 768px) {
  .contact-container {
    width: 100%;
    height: 100%;
    margin-top: 0;
  }
}
