a {
  color: inherit;
}
.nav {
  position: fixed;
  top: 0;
  width: 100%;
  padding: 10px;
  height: 18px;
  z-index: 1;
  display: flex;
  background-color: black;
  align-items: center;
  justify-content: center;
}

.nav-github {
  position: fixed;
  align-items: center;
  right: 10px;
  color: beige;
  margin-left: 10px;
}

.nav-ig {
  position: fixed;
  align-items: center;
  right: 45px;
  margin-left: 10px;
  color: beige;
}

.nav-linkedIn {
  right: 80px;
  position: fixed;
  color: beige;
  align-items: center;
}
