a {
  text-decoration: none;
}

.card-container {
  width: 200px;
  height: 400px;
  overflow: hidden;
  box-shadow: 0px 0px 15px -5px;
  margin: 20px;
  object-fit: contain;
  border-radius: 10px;
}

.card-container:hover {
  transform: scale(1.1);
  box-shadow: 0px 0px 15px 0px;
}

.image-container img {
  overflow: hidden;
  height: 200px;
  justify-content: center;
}
.card-title {
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  text-align: center;
}

.card-body {
  padding: 5px;
}

.btn {
  display: flex;
  justify-content: center;
}

.project-text {
  font-size: 15px;
  margin: 10px;
}
